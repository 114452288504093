/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddJobVacancySpec,
  DownloadDocumentsRequest,
  JobVacancyApplicationStatus,
  ResponseWrapperOfAppliedCrewResponse,
  ResponseWrapperOfGetApplicantStatusHistoryResponse,
  ResponseWrapperOfGetApplicantSummariesByStatusResponse,
  ResponseWrapperOfGetApplicantSummariesResponse,
  ResponseWrapperOfGetAppliedCrewsResponse,
  ResponseWrapperOfGetDocumentsResponse,
  ResponseWrapperOfGetJobPositionsResponse,
  ResponseWrapperOfGetJobVacanciesResponse,
  ResponseWrapperOfGetLegalMinimumCertificatesResponse,
  ResponseWrapperOfGetListByPositionResponse,
  ResponseWrapperOfGetVesselTypesResponse,
  ResponseWrapperOfJobVacancyResponse,
  ResponseWrapperOfString,
  SortByType,
  SortDirection,
  UpdateJobVacancySpec,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class JobVacancy<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyAddJobVacancy
   * @summary Add a new job vacancy for the logged in company user.
   * @request POST:/v1/job-vacancy
   * @secure
   */
  jobVacancyAddJobVacancy = (
    data: AddJobVacancySpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfJobVacancyResponse, any>({
      path: `/v1/job-vacancy`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyGetJobVacancies
   * @summary Get all job vacancies created by user.
   * @request GET:/v1/job-vacancy
   * @secure
   */
  jobVacancyGetJobVacancies = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetJobVacanciesResponse, any>({
      path: `/v1/job-vacancy`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyUpdateJobVacancy
   * @summary Update the data of a given job vacancy id.
   * @request PUT:/v1/job-vacancy
   * @secure
   */
  jobVacancyUpdateJobVacancy = (
    data: UpdateJobVacancySpec,
    query?: { jobVacancyId?: string },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/job-vacancy`,
      method: 'PUT',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyDeleteJobVacancy
   * @summary Delete a job vacancy with have the given id, that owns by the logged in user.
   * @request DELETE:/v1/job-vacancy
   * @secure
   */
  jobVacancyDeleteJobVacancy = (
    query?: { jobVacancyId?: string },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/job-vacancy`,
      method: 'DELETE',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyGetJobVacancy
   * @summary Get the detail of job vacancy for the given job vacancy id.
   * @request GET:/v1/job-vacancy/{id}
   * @secure
   */
  jobVacancyGetJobVacancy = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfJobVacancyResponse, any>({
      path: `/v1/job-vacancy/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyGetLegalMinimumCertificates
   * @summary Get the available legal minimum certificates to create a job vacancy.
   * @request GET:/v1/job-vacancy/legal-minimum-certificates
   * @secure
   */
  jobVacancyGetLegalMinimumCertificates = (
    query?: { JobPosition?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfGetLegalMinimumCertificatesResponse,
      any
    >({
      path: `/v1/job-vacancy/legal-minimum-certificates`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyGetApplicants
   * @summary Search applicants of a job vacancy.
   * @request GET:/v1/job-vacancy/applied/{id}
   * @secure
   */
  jobVacancyGetApplicants = (
    id: string,
    query?: {
      Limit?: number;
      CurrentlyAvailable?: boolean | null;
      'Sort.By'?: SortByType;
      'Sort.Direction'?: SortDirection;
      'Sort.Latitude'?: number | null;
      'Sort.Longitude'?: number | null;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetAppliedCrewsResponse, any>({
      path: `/v1/job-vacancy/applied/${id}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name JobVacancyGetApplicants2
   * @summary Get detail of applicant with the given applicant id.
   * @request GET:/v1/job-vacancy/applicant/detail/{applicantId}
   * @secure
   */
  jobVacancyGetApplicants2 = (
    applicantId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfAppliedCrewResponse, any>({
      path: `/v1/job-vacancy/applicant/detail/${applicantId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate of Endorsements
   * @name GetListByPositionHandle
   * @summary Get list of certificate of endorsements for the given job position.
   * @request GET:/v1/job-vacancy/endorsements
   * @secure
   */
  getListByPositionHandle = (
    query?: { JobPosition?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetListByPositionResponse, any>({
      path: `/v1/job-vacancy/endorsements`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name DownloadDocumentsHandle
   * @summary Download hired applicant's selected document.
   * @request POST:/v1/job-vacancy/download-applicant-document
   * @secure
   */
  downloadDocumentsHandle = (
    data: DownloadDocumentsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<File, any>({
      path: `/v1/job-vacancy/download-applicant-document`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name GetApplicantStatusHistoryHandle
   * @summary Get applicant's status history
   * @request GET:/v1/job-vacancy/applicant/status-history/{jobVacancyId}
   * @secure
   */
  getApplicantStatusHistoryHandle = (
    jobVacancyId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetApplicantStatusHistoryResponse, any>({
      path: `/v1/job-vacancy/applicant/status-history/${jobVacancyId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name GetApplicantSummariesHandle
   * @summary Get job applicant summaries with status 'APPLIED'.
   * @request GET:/v1/job-vacancy/applicant/summaries/{jobVacancyId}
   * @secure
   */
  getApplicantSummariesHandle = (
    jobVacancyId: string,
    query?: {
      Limit?: number;
      CurrentlyAvailable?: boolean | null;
      'Sort.By'?: SortByType;
      'Sort.Direction'?: SortDirection;
      'Sort.Latitude'?: number | null;
      'Sort.Longitude'?: number | null;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetApplicantSummariesResponse, any>({
      path: `/v1/job-vacancy/applicant/summaries/${jobVacancyId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name GetApplicantSummariesByStatusHandle
   * @summary Get job vacancy applicant with the given status.
   * @request GET:/v1/job-vacancy/applicant/summaries
   * @secure
   */
  getApplicantSummariesByStatusHandle = (
    query?: {
      JobVacancyId?: string;
      Status?: JobVacancyApplicationStatus[] | null;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfGetApplicantSummariesByStatusResponse,
      any
    >({
      path: `/v1/job-vacancy/applicant/summaries`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name GetDocumentsHandle
   * @summary Get all downloadable documents for a given applicant ID
   * @request GET:/v1/job-vacancy/applicant/documents/{applicantId}
   * @secure
   */
  getDocumentsHandle = (applicantId: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetDocumentsResponse, any>({
      path: `/v1/job-vacancy/applicant/documents/${applicantId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name GetJobPositionsHandle
   * @summary Get available job positions, grouped by department.
   * @request GET:/v1/job-vacancy/positions
   * @secure
   */
  getJobPositionsHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetJobPositionsResponse, any>({
      path: `/v1/job-vacancy/positions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Vacancy
   * @name GetVesselTypesHandle
   * @summary Get available vessel types that user can pick.
   * @request GET:/v1/job-vacancy/vessel-types
   * @secure
   */
  getVesselTypesHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetVesselTypesResponse, any>({
      path: `/v1/job-vacancy/vessel-types`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
