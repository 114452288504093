/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ResponseWrapperOfString } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Notification<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Expiring Documents
   * @name SendNotificationHandle
   * @summary Send a notification to crews if they have an expiring document(s).
   * @request PUT:/v1/notification/expiring-documents
   * @secure
   */
  sendNotificationHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/notification/expiring-documents`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
}
