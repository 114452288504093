/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GoogleLoginHandlerRequest,
  ResponseWrapperOfGoogleLoginHandlerResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Google<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Login
   * @name GoogleLoginHandle
   * @summary Log in crew user using a Google login.
   * @request POST:/v1/google/login
   * @secure
   */
  googleLoginHandle = (
    data: GoogleLoginHandlerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGoogleLoginHandlerResponse, any>({
      path: `/v1/google/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
