/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddEmergencyContactSpec,
  AddHomeSchoolSpec,
  AddRatingSpec,
  AddWorkExperienceSpec,
  ApplyJobVacancySpec,
  ChangePasswordRequest,
  CheckSsoPlatformRequest,
  CreateCertificateRequest,
  CreateConnectionRequestRequest,
  CreateMedicalCheckUpRequest,
  CreateTravelDocumentRequest,
  CreateVaccinationRequest,
  LogoutRequest,
  RegisterCrewSpec,
  RegisterEmailRequest,
  RegisterFacebookRequest,
  RegisterGoogleRequest,
  RequestOtpRequest,
  ResetPasswordRequest,
  ResponseWrapperOfCertificateDto,
  ResponseWrapperOfCertificateResponse,
  ResponseWrapperOfCheckSsoPlatformResponse,
  ResponseWrapperOfCreateConnectionRequestHandlerResponse,
  ResponseWrapperOfCreateVaccinationResponse,
  ResponseWrapperOfEmergencyContact,
  ResponseWrapperOfGetAllCertificatesResponse,
  ResponseWrapperOfGetArchivedCertificatesResponse,
  ResponseWrapperOfGetArchivedTravelDocumentsResponse,
  ResponseWrapperOfGetCertificateUrlResponse,
  ResponseWrapperOfGetConnectionRequestsResponse,
  ResponseWrapperOfGetConnectionsResponse,
  ResponseWrapperOfGetCrewByReferralCodeResponse,
  ResponseWrapperOfGetCrewProfileHandlerResponse,
  ResponseWrapperOfGetCrewProfileResponse,
  ResponseWrapperOfGetCvUrlResponse,
  ResponseWrapperOfGetDocumentUrlResponse,
  ResponseWrapperOfGetExpiringCertificateResponse,
  ResponseWrapperOfGetExpiringTravelDocumentResponse,
  ResponseWrapperOfGetFacebookUserInfoResponse,
  ResponseWrapperOfGetIdCardsByCrewIdHandlerResponse,
  ResponseWrapperOfGetJobVacancyApplicationsResponse,
  ResponseWrapperOfGetListByCrewIdHandlerResponse,
  ResponseWrapperOfGetListVaccinationHandlerResponse,
  ResponseWrapperOfGetMedicalCheckUpListResponse,
  ResponseWrapperOfGetMutualResponse,
  ResponseWrapperOfGetProfilePicResponse,
  ResponseWrapperOfGetPublicProfileDataHandlerResponse,
  ResponseWrapperOfGetReferralCodeHandlerResponse,
  ResponseWrapperOfGetSeamanBookListByCrewIdHandlerResponse,
  ResponseWrapperOfGetSummaryResponse,
  ResponseWrapperOfGetTotalConnectionsHandlerResponse,
  ResponseWrapperOfGetTravelDocumentsResponse,
  ResponseWrapperOfGetVaccinationListResponse,
  ResponseWrapperOfGetVisaListByCrewIdHandlerResponse,
  ResponseWrapperOfGetWorkExperiencesResponse,
  ResponseWrapperOfHomeSchool,
  ResponseWrapperOfIdCardDto,
  ResponseWrapperOfLastCvHandlerResponse,
  ResponseWrapperOfListOfGetCrewSuggestionsResponse,
  ResponseWrapperOfListOfRelatedCrewsHandlerResponse,
  ResponseWrapperOfMedicalCheckUpDto,
  ResponseWrapperOfPassportDto,
  ResponseWrapperOfRating,
  ResponseWrapperOfReadCvResponse,
  ResponseWrapperOfRegisterEmailResponse,
  ResponseWrapperOfRegisterFacebookResponse,
  ResponseWrapperOfRegisterGoogleResponse,
  ResponseWrapperOfRequestOtpResponse,
  ResponseWrapperOfSeamanBookDto,
  ResponseWrapperOfSearchCocHandlerResponse,
  ResponseWrapperOfSearchCopHandlerResponse,
  ResponseWrapperOfSearchJobVacancyResponse,
  ResponseWrapperOfSearchResponse,
  ResponseWrapperOfStoreCertificateHandlerResponse,
  ResponseWrapperOfStoreTravelDocumentHandlerResponse,
  ResponseWrapperOfString,
  ResponseWrapperOfSubmitOtpResponse,
  ResponseWrapperOfTravelDocumentResponse,
  ResponseWrapperOfUpdateMedicalCheckUpHandlerResponse,
  ResponseWrapperOfUpdateVaccinationResponse,
  ResponseWrapperOfUploadAndCreateCertificateRecordHandlerResponse,
  ResponseWrapperOfUploadFileResponse,
  ResponseWrapperOfUploadIdCardFileHandlerResponse,
  ResponseWrapperOfUploadMedicalCheckUpFileResponse,
  ResponseWrapperOfUploadPassportFileHandlerResponse,
  ResponseWrapperOfUploadScannedVaccinationAndCreateRecordHandlerResponse,
  ResponseWrapperOfUploadScannedVisaHandlerResponse,
  ResponseWrapperOfUploadSeamanBookFileHandlerResponse,
  ResponseWrapperOfUploadSocialMediaResponse,
  ResponseWrapperOfUploadVaccinationFileResponse,
  ResponseWrapperOfVisaDto,
  ResponseWrapperOfWorkExperienceResponse,
  SetAccountPrivateRequest,
  StoreIdCardRequest,
  StorePassportRequest,
  StoreSeamanBookRequest,
  StoreVisaRequest,
  SubmitOtpRequest,
  SubmitRequest,
  UpdateCertificateSpec,
  UpdateCrewProfileSpec,
  UpdateIdCardRequest,
  UpdateLocationSpec,
  UpdateMedicalCheckUpRequest,
  UpdatePassportRequest,
  UpdateSeamanBookRequest,
  UpdateTravelDocumentSpec,
  UpdateVaccinationRequest,
  UpdateVisaRequest,
  UpdateWorkExperienceSpec,
  WithdrawJobApplicationSpec,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Crew<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Certificate
   * @name GetCertificateByIdGetCrewCertificateById
   * @summary Get certificate by id
   * @request GET:/v1/crew/certificate/{id}
   * @secure
   */
  getCertificateByIdGetCrewCertificateById = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCertificateDto, any>({
      path: `/v1/crew/certificate/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name CertificateRemoveCertificate
   * @summary Removes the detail of the given certificate id.
   * @request DELETE:/v1/crew/certificate/{id}
   * @secure
   */
  certificateRemoveCertificate = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/certificate/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name CertificateUpdateCertificate
   * @summary Update logged in crew's certificate data.
   * @request PUT:/v1/crew/certificate/{id}
   * @secure
   */
  certificateUpdateCertificate = (
    id: string,
    data: UpdateCertificateSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCertificateResponse, any>({
      path: `/v1/crew/certificate/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name GetIdCardByIdGetCrewIdCardById
   * @summary get id card by id
   * @request GET:/v1/crew/id-card/{id}
   * @secure
   */
  getIdCardByIdGetCrewIdCardById = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfIdCardDto, any>({
      path: `/v1/crew/id-card/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name DeleteIdCardHandleDeleteIdCard
   * @summary Delete an existing id card.
   * @request DELETE:/v1/crew/id-card/{id}
   * @secure
   */
  deleteIdCardHandleDeleteIdCard = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/id-card/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name UpdateIdCardHandleUpdateIdCard
   * @summary Update existing id card data.
   * @request PUT:/v1/crew/id-card/{id}
   * @secure
   */
  updateIdCardHandleUpdateIdCard = (
    id: string,
    data: UpdateIdCardRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/id-card/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up
   * @name GetMcuByIdGetCrewMcuById
   * @summary Get mcu by id
   * @request GET:/v1/crew/mcu/{id}
   * @secure
   */
  getMcuByIdGetCrewMcuById = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfMedicalCheckUpDto, any>({
      path: `/v1/crew/mcu/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up
   * @name DeleteMcuDeleteMedicalCheckUp
   * @summary Remove existing crew's medical check up
   * @request DELETE:/v1/crew/mcu/{id}
   * @secure
   */
  deleteMcuDeleteMedicalCheckUp = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/mcu/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up
   * @name UpdateMcuUpdateMedicalCheckUp
   * @summary Update existing crew's a medical check-up record.
   * @request PUT:/v1/crew/mcu/{id}
   * @secure
   */
  updateMcuUpdateMedicalCheckUp = (
    id: string,
    data: UpdateMedicalCheckUpRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUpdateMedicalCheckUpHandlerResponse,
      any
    >({
      path: `/v1/crew/mcu/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Get Crew Mutual
   * @name GetMutualGetListMutual
   * @summary Get crew mutual list
   * @request GET:/v1/crew/mutual
   * @secure
   */
  getMutualGetListMutual = (
    query?: { CrewId?: number },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetMutualResponse, any>({
      path: `/v1/crew/mutual`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name GetPassportByIdGetCrewPassportById
   * @summary Get passport by id
   * @request GET:/v1/crew/passport/{id}
   * @secure
   */
  getPassportByIdGetCrewPassportById = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfPassportDto, any>({
      path: `/v1/crew/passport/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name DeletePassportHandleDeletePassport
   * @summary Delete an existing passport.
   * @request DELETE:/v1/crew/passport/{id}
   * @secure
   */
  deletePassportHandleDeletePassport = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/passport/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name UpdatePassportHandleUpdatePassport
   * @summary Update existing passport data.
   * @request PUT:/v1/crew/passport/{id}
   * @secure
   */
  updatePassportHandleUpdatePassport = (
    id: string,
    data: UpdatePassportRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/passport/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name GetSeamanBookByIdGetCrewSeamanBookById
   * @summary get seaman book by id
   * @request GET:/v1/crew/seaman-book/{id}
   * @secure
   */
  getSeamanBookByIdGetCrewSeamanBookById = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSeamanBookDto, any>({
      path: `/v1/crew/seaman-book/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name DeleteSeamanBookHandleDeleteSeamanBook
   * @summary Delete an existing seaman book.
   * @request DELETE:/v1/crew/seaman-book/{id}
   * @secure
   */
  deleteSeamanBookHandleDeleteSeamanBook = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/seaman-book/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name UpdateSeamanBookHandleUpdateSeamanBook
   * @summary Update existing seaman book data.
   * @request PUT:/v1/crew/seaman-book/{id}
   * @secure
   */
  updateSeamanBookHandleUpdateSeamanBook = (
    id: string,
    data: UpdateSeamanBookRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/seaman-book/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Create personal social media post
   * @name UploadSocialMediaUploadAndGetSocialUrl
   * @summary Upload social media
   * @request POST:/v1/crew/social-media
   * @secure
   */
  uploadSocialMediaUploadAndGetSocialUrl = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadSocialMediaResponse, any>({
      path: `/v1/crew/social-media`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination
   * @name GetVaccinationByIdGetCrewVaccinationById
   * @summary get vaccine by id
   * @request GET:/v1/crew/vaccination/{id}
   * @secure
   */
  getVaccinationByIdGetCrewVaccinationById = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetListVaccinationHandlerResponse, any>({
      path: `/v1/crew/vaccination/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name GetVisaByIdGetCrewVisaById
   * @summary Get visa by id
   * @request GET:/v1/crew/visa/{id}
   * @secure
   */
  getVisaByIdGetCrewVisaById = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfVisaDto, any>({
      path: `/v1/crew/visa/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name DeleteVisaHandleDeleteVisa
   * @summary Delete an existing visa.
   * @request DELETE:/v1/crew/visa/{id}
   * @secure
   */
  deleteVisaHandleDeleteVisa = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/visa/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name UpdateVisaHandleUpdateVisa
   * @summary Update existing visa data.
   * @request PUT:/v1/crew/visa/{id}
   * @secure
   */
  updateVisaHandleUpdateVisa = (
    id: string,
    data: UpdateVisaRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/visa/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags OTP
   * @name ResendOtpHandle
   * @summary Resend otp for crew user.
   * @request POST:/v1/crew/otp/resend
   * @secure
   */
  resendOtpHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/otp/resend`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags OTP
   * @name SubmitHandle
   * @summary Submit OTP to finalize registration process for crew users.
   * @request POST:/v1/crew/otp/submit
   * @secure
   */
  submitHandle = (data: SubmitRequest, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/otp/submit`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ForgotPassword
   * @name RequestOtpHandle
   * @summary Request OTP.
   * @request POST:/v1/crew/forgot-password
   * @secure
   */
  requestOtpHandle = (data: RequestOtpRequest, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfRequestOtpResponse, any>({
      path: `/v1/crew/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ForgotPassword
   * @name ResetPasswordHandle
   * @summary Reset password using forgot password otp.
   * @request POST:/v1/crew/forgot-password/change-password
   * @secure
   */
  resetPasswordHandle = (
    data: ResetPasswordRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/forgot-password/change-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ForgotPassword
   * @name SubmitOtpHandle
   * @summary Submit Forgot Password OTP.
   * @request POST:/v1/crew/forgot-password/submit
   * @secure
   */
  submitOtpHandle = (data: SubmitOtpRequest, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfSubmitOtpResponse, any>({
      path: `/v1/crew/forgot-password/submit`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Logout
   * @name LogoutHandle
   * @summary Revoke usage of current login token and remove firebase device token.
   * @request POST:/v1/crew/user/log-out
   * @secure
   */
  logoutHandle = (data: LogoutRequest, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/user/log-out`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Change Password
   * @name ChangePasswordHandle
   * @summary Change Password from Android App
   * @request POST:/v1/crew/change-password
   * @secure
   */
  changePasswordHandle = (
    data: ChangePasswordRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/change-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewDeleteDocuments
   * @summary Removes crew's travel documents and certificates. This will only removes the data, not the actual scanned document(s).
   * @request DELETE:/v1/crew/documents/all
   * @secure
   */
  crewDeleteDocuments = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/documents/all`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewReadCv
   * @summary Read the contents of the pdf, and returns it in a JSON format.
   * @request POST:/v1/crew/cv/read
   * @secure
   */
  crewReadCv = (data: { file?: File | null }, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfReadCvResponse, any>({
      path: `/v1/crew/cv/read`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewRegisterCrew
   * @summary Add crew's personal data.
   * @request POST:/v1/crew
   * @secure
   */
  crewRegisterCrew = (data: RegisterCrewSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetCrewProfileResponse, any>({
      path: `/v1/crew`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewUpdateCrewProfile
   * @summary Update crew profile
   * @request PUT:/v1/crew
   * @secure
   */
  crewUpdateCrewProfile = (
    data: UpdateCrewProfileSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetCrewProfileResponse, any>({
      path: `/v1/crew`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name GetProfileGetCrewProfile
   * @summary Get crew's profile data.
   * @request GET:/v1/crew
   * @secure
   */
  getProfileGetCrewProfile = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetCrewProfileHandlerResponse, any>({
      path: `/v1/crew`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewGetRelatedCrews
   * @summary Get related contact
   * @request GET:/v1/crew/related
   * @secure
   */
  crewGetRelatedCrews = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfListOfRelatedCrewsHandlerResponse, any>({
      path: `/v1/crew/related`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewGetCvPdf
   * @summary Get Crew CV in PDF
   * @request GET:/v1/crew/get-cv-pdf
   * @secure
   */
  crewGetCvPdf = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetCvUrlResponse, any>({
      path: `/v1/crew/get-cv-pdf`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewUploadProfilePic
   * @summary Upload a profile pic for the logged-in crew.
   * @request POST:/v1/crew/profile-pic
   * @secure
   */
  crewUploadProfilePic = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetProfilePicResponse, any>({
      path: `/v1/crew/profile-pic`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewDeleteProfilePictureUrl
   * @summary Delete user profile picture
   * @request DELETE:/v1/crew/profile-pic
   * @secure
   */
  crewDeleteProfilePictureUrl = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/profile-pic`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewGetProfilePicUrl
   * @summary Get logged-in crew's profile pic URL.
   * @request GET:/v1/crew/profile-pic
   * @secure
   */
  crewGetProfilePicUrl = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetProfilePicResponse, any>({
      path: `/v1/crew/profile-pic`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewLastUploadedCv
   * @summary Get last uploaded Cv.
   * @request GET:/v1/crew/last-cv
   * @secure
   */
  crewLastUploadedCv = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfLastCvHandlerResponse, any>({
      path: `/v1/crew/last-cv`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewProfileComplete
   * @summary Mark logged-in crew's profile data (documents, certificates, & work experiences) as completely filled.
   * @request PUT:/v1/crew/profile-complete
   * @secure
   */
  crewProfileComplete = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/profile-complete`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewWelcomeComplete
   * @summary Mark complete status
   * @request PUT:/v1/crew/welcome-complete
   * @secure
   */
  crewWelcomeComplete = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/welcome-complete`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name CrewUpdateLocation
   * @summary Update logged-in crew's location.
   * @request PUT:/v1/crew/location
   * @secure
   */
  crewUpdateLocation = (data: UpdateLocationSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetCrewProfileResponse, any>({
      path: `/v1/crew/location`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Public Profile
   * @name GetPublicProfileDataHandle
   * @summary Get crew's public profile data
   * @request GET:/v1/crew/public-profile/{crewId}
   * @secure
   */
  getPublicProfileDataHandle = (crewId: string, params: RequestParams = {}) =>
    this.http.request<
      ResponseWrapperOfGetPublicProfileDataHandlerResponse,
      any
    >({
      path: `/v1/crew/public-profile/${crewId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew
   * @name SearchCrewHandle
   * @summary Seach crew by their name.
   * @request GET:/v1/crew/search
   * @secure
   */
  searchCrewHandle = (
    query?: { Name?: string | null; Page?: number; PageSize?: number },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchResponse, any>({
      path: `/v1/crew/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account Privacy
   * @name SetAccountPrivateSetAccountPrivate
   * @summary Toggle's account privacy level.
   * @request PUT:/v1/crew/set-private
   * @secure
   */
  setAccountPrivateSetAccountPrivate = (
    data: SetAccountPrivateRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/set-private`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkExperience
   * @name WorkExperienceAddWorkExperience
   * @summary Add a new work experience data for the logged in crew.
   * @request POST:/v1/crew/work-experience
   * @secure
   */
  workExperienceAddWorkExperience = (
    data: AddWorkExperienceSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfWorkExperienceResponse, any>({
      path: `/v1/crew/work-experience`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkExperience
   * @name WorkExperienceGetWorkExperiences
   * @summary Get all the saved work experience of the logged in crew.
   * @request GET:/v1/crew/work-experience
   * @secure
   */
  workExperienceGetWorkExperiences = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetWorkExperiencesResponse, any>({
      path: `/v1/crew/work-experience`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkExperience
   * @name WorkExperienceUpdateWorkExperience
   * @summary Update existing work experience with the given ID, that owned by the logged in crew.
   * @request PUT:/v1/crew/work-experience/{workExperienceId}
   * @secure
   */
  workExperienceUpdateWorkExperience = (
    workExperienceId: string,
    data: UpdateWorkExperienceSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/work-experience/${workExperienceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkExperience
   * @name WorkExperienceRemove
   * @summary Remove existing work experience with the given ID, which owned by the logged in crew.
   * @request DELETE:/v1/crew/work-experience/{workExperienceId}
   * @secure
   */
  workExperienceRemove = (
    workExperienceId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/work-experience/${workExperienceId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name ArchiveVisaHandleArchiveVisa
   * @summary Archive existing visa.
   * @request PUT:/v1/crew/visa/archive/{id}
   * @secure
   */
  archiveVisaHandleArchiveVisa = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/visa/archive/${id}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name DeleteScannedVisaHandleDeleteScannedVisa
   * @summary Delete existing file from a visa.
   * @request DELETE:/v1/crew/visa/file/{id}
   * @secure
   */
  deleteScannedVisaHandleDeleteScannedVisa = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/visa/file/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name UploadScannedVisaFilesUploadScannedVisa
   * @summary Upload and read scanned visa to an existing data.
   * @request PUT:/v1/crew/visa/file/{id}
   * @secure
   */
  uploadScannedVisaFilesUploadScannedVisa = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadScannedVisaHandlerResponse, any>({
      path: `/v1/crew/visa/file/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name GetAllVisaHandleGetAllVisa
   * @summary Get list of visas for the logged-in crew.
   * @request GET:/v1/crew/visa
   * @secure
   */
  getAllVisaHandleGetAllVisa = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetVisaListByCrewIdHandlerResponse, any>(
      {
        path: `/v1/crew/visa`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags Visa
   * @name StoreVisaHandleStoreVisa
   * @summary Store new visa for the logged-in crew.
   * @request POST:/v1/crew/visa
   * @secure
   */
  storeVisaHandleStoreVisa = (
    data: StoreVisaRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfVisaDto, any>({
      path: `/v1/crew/visa`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Visa
   * @name UploadReadScannedVisaUploadScannedFileAndCreateVisa
   * @summary Upload and read scanned visa.
   * @request POST:/v1/crew/visa/read
   * @secure
   */
  uploadReadScannedVisaUploadScannedFileAndCreateVisa = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfVisaDto, any>({
      path: `/v1/crew/visa/read`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination
   * @name CreateVaccinationHandleCreateVaccination
   * @summary Store crew's a vaccination record.
   * @request POST:/v1/crew/vaccination
   * @secure
   */
  createVaccinationHandleCreateVaccination = (
    data: CreateVaccinationRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCreateVaccinationResponse, any>({
      path: `/v1/crew/vaccination`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination
   * @name GetVaccinationListGetAllVaccinations
   * @summary Get crew's vaccination record.
   * @request GET:/v1/crew/vaccination
   * @secure
   */
  getVaccinationListGetAllVaccinations = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetVaccinationListResponse, any>({
      path: `/v1/crew/vaccination`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination
   * @name DeleteVaccinationHandleDeleteVaccination
   * @summary Store crew's a vaccination record.
   * @request DELETE:/v1/crew/vaccination/{vaccinationId}
   * @secure
   */
  deleteVaccinationHandleDeleteVaccination = (
    vaccinationId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/vaccination/${vaccinationId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination
   * @name UpdateVaccinationHandleUpdateVaccination
   * @summary Store crew's a vaccination record.
   * @request PUT:/v1/crew/vaccination/{vaccinationId}
   * @secure
   */
  updateVaccinationHandleUpdateVaccination = (
    vaccinationId: string,
    data: UpdateVaccinationRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUpdateVaccinationResponse, any>({
      path: `/v1/crew/vaccination/${vaccinationId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination
   * @name UploadAndCreateUploadScannedFileAndCreateVaccination
   * @summary Upload vaccination certificate(s) and create new vaccination record.
   * @request POST:/v1/crew/vaccination/upload
   * @secure
   */
  uploadAndCreateUploadScannedFileAndCreateVaccination = (
    data: { files?: File[] | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadScannedVaccinationAndCreateRecordHandlerResponse,
      any
    >({
      path: `/v1/crew/vaccination/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination Certificate
   * @name DeleteVaccinationFileDeleteVaccination
   * @summary Delete crew's scanned vaccination certificate.
   * @request DELETE:/v1/crew/vaccination/file/{vaccinationFileId}
   * @secure
   */
  deleteVaccinationFileDeleteVaccination = (
    vaccinationFileId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/vaccination/file/${vaccinationFileId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Vaccination Certificate
   * @name UploadUploadScannedVaccineCertificate
   * @summary Upload crew's scanned vaccination certificate.
   * @request PUT:/v1/crew/vaccination/file/{vaccinationId}
   * @secure
   */
  uploadUploadScannedVaccineCertificate = (
    vaccinationId: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadVaccinationFileResponse, any>({
      path: `/v1/crew/vaccination/file/${vaccinationId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Travel Document
   * @name ArchiveTravelDocumentHandle
   * @summary Archive existing travel document owned by the logged in crew.
   * @request POST:/v1/crew/archive-travel-document/{id}
   * @secure
   */
  archiveTravelDocumentHandle = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/archive-travel-document/${id}`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Travel Document
   * @name CreateTravelDocumentHandle
   * @summary Record a travel document for the logged in crew.
   * @request POST:/v1/crew/travel-document
   * @secure
   */
  createTravelDocumentHandle = (
    data: CreateTravelDocumentRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfStoreTravelDocumentHandlerResponse, any>(
      {
        path: `/v1/crew/travel-document`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags Travel Document
   * @name TravelDocumentGetTravelDocuments
   * @summary Get all travel documents owned by the logged in crew.
   * @request GET:/v1/crew/travel-document
   * @secure
   */
  travelDocumentGetTravelDocuments = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetTravelDocumentsResponse, any>({
      path: `/v1/crew/travel-document`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Past Documents
   * @name GetArchivedTravelDocumentsHandle
   * @summary Get archived travel documents.
   * @request GET:/v1/crew/archive-travel-document
   * @secure
   */
  getArchivedTravelDocumentsHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetArchivedTravelDocumentsResponse, any>(
      {
        path: `/v1/crew/archive-travel-document`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags Travel Document
   * @name TravelDocumentUpdateTravelDocument
   * @summary Update existing travel document owned by the logged in crew.
   * @request PUT:/v1/crew/travel-document/{travelDocumentId}
   * @secure
   */
  travelDocumentUpdateTravelDocument = (
    travelDocumentId: string,
    data: UpdateTravelDocumentSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfTravelDocumentResponse, any>({
      path: `/v1/crew/travel-document/${travelDocumentId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Travel Document
   * @name TravelDocumentRemove
   * @summary Remove a travel document with the given travel document ID.
   * @request DELETE:/v1/crew/travel-document/{travelDocumentId}
   * @secure
   */
  travelDocumentRemove = (
    travelDocumentId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/travel-document/${travelDocumentId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Travel Document
   * @name TravelDocumentUploadFile
   * @summary Upload a scanned travel document for the given travel document ID. The travel document has to be owned by the logged in crew.
   * @request PUT:/v1/crew/travel-document/upload/{id}
   * @secure
   */
  travelDocumentUploadFile = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadFileResponse, any>({
      path: `/v1/crew/travel-document/upload/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Travel Document
   * @name TravelDocumentDeleteFile
   * @summary Delete an uploaded scanned travel document of the travel document id.
   * @request DELETE:/v1/crew/travel-document/file/{id}
   * @secure
   */
  travelDocumentDeleteFile = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/travel-document/file/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Travel Document
   * @name TravelDocumentGetUrl
   * @summary Get a URL of the uploaded scanned travel document for the travel document ID.
   * @request GET:/v1/crew/travel-document/file-url/{id}
   * @secure
   */
  travelDocumentGetUrl = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetDocumentUrlResponse, any>({
      path: `/v1/crew/travel-document/file-url/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name ArchiveSeamanBookHandleArchiveSeamanBook
   * @summary Archive existing seaman book.
   * @request PUT:/v1/crew/seaman-book/archive/{id}
   * @secure
   */
  archiveSeamanBookHandleArchiveSeamanBook = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/seaman-book/archive/${id}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name DeleteSeamanBookFileDeleteScannedSeamanBook
   * @summary Delete existing scanned seaman book file.
   * @request DELETE:/v1/crew/seaman-book/file/{id}
   * @secure
   */
  deleteSeamanBookFileDeleteScannedSeamanBook = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/seaman-book/file/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name UploadFileUploadScannedSeamanBook
   * @summary Upload and read scanned seaman book to an existing seaman book data.
   * @request PUT:/v1/crew/seaman-book/file/{id}
   * @secure
   */
  uploadFileUploadScannedSeamanBook = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadSeamanBookFileHandlerResponse,
      any
    >({
      path: `/v1/crew/seaman-book/file/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name GetAllSeamanBookGetAllSeamanBooks
   * @summary Get list of seaman books for the logged-in crew.
   * @request GET:/v1/crew/seaman-book
   * @secure
   */
  getAllSeamanBookGetAllSeamanBooks = (params: RequestParams = {}) =>
    this.http.request<
      ResponseWrapperOfGetSeamanBookListByCrewIdHandlerResponse,
      any
    >({
      path: `/v1/crew/seaman-book`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name StoreSeamanBookHandle
   * @summary Create a new seaman book for the logged-in crew.
   * @request POST:/v1/crew/seaman-book
   * @secure
   */
  storeSeamanBookHandle = (
    data: StoreSeamanBookRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSeamanBookDto, any>({
      path: `/v1/crew/seaman-book`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Seaman Book
   * @name UploadSeamanBookUploadScannedFileAndCreateSeamanBook
   * @summary Upload and read scanned seaman book photo(s).
   * @request POST:/v1/crew/seaman-book/read
   * @secure
   */
  uploadSeamanBookUploadScannedFileAndCreateSeamanBook = (
    data: { files?: File[] | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSeamanBookDto, any>({
      path: `/v1/crew/seaman-book/read`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Register
   * @name CheckSsoPlatformHandle
   * @summary Check whether the email of the SSO token owner exist in crew user table. If yes, and on the same SSO Platform, generate login token.
   * @request POST:/v1/crew/register/sso-check
   * @secure
   */
  checkSsoPlatformHandle = (
    data: CheckSsoPlatformRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCheckSsoPlatformResponse, any>({
      path: `/v1/crew/register/sso-check`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Register
   * @name GetFacebookUserInfoHandle
   * @summary Get email of the owner of the fb token.
   * @request GET:/v1/crew/register/fb-user-info
   * @secure
   */
  getFacebookUserInfoHandle = (
    query?: { Token?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetFacebookUserInfoResponse, any>({
      path: `/v1/crew/register/fb-user-info`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Register
   * @name RegisterGoogleHandle
   * @summary Register new crew user using Google account.
   * @request POST:/v1/crew/register/google
   * @secure
   */
  registerGoogleHandle = (
    data: RegisterGoogleRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfRegisterGoogleResponse, any>({
      path: `/v1/crew/register/google`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Register
   * @name RegisterFacebookHandle
   * @summary Register new crew user using Facebook account.
   * @request POST:/v1/crew/register/facebook
   * @secure
   */
  registerFacebookHandle = (
    data: RegisterFacebookRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfRegisterFacebookResponse, any>({
      path: `/v1/crew/register/facebook`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Register
   * @name RegisterEmailHandle
   * @summary Register a new crew user using email & password.
   * @request POST:/v1/crew/register/email
   * @secure
   */
  registerEmailHandle = (
    data: RegisterEmailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfRegisterEmailResponse, any>({
      path: `/v1/crew/register/email`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Referrals
   * @name GetCrewByReferralCodeHandle
   * @summary Get crew's data from referral code
   * @request GET:/v1/crew/referral/get-crew
   * @secure
   */
  getCrewByReferralCodeHandle = (
    query?: { referralCode?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetCrewByReferralCodeResponse, any>({
      path: `/v1/crew/referral/get-crew`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Referrals
   * @name GetReferralCodeHandle
   * @summary Get crew's referral code.
   * @request GET:/v1/crew/referral
   * @secure
   */
  getReferralCodeHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetReferralCodeHandlerResponse, any>({
      path: `/v1/crew/referral`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Rating
   * @name RatingCreateRating
   * @summary set rating
   * @request POST:/v1/crew/rating
   * @secure
   */
  ratingCreateRating = (data: AddRatingSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfRating, any>({
      path: `/v1/crew/rating`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name ArchivePassportHandleArchivePassport
   * @summary Archive existing passport.
   * @request PUT:/v1/crew/passport/archive/{id}
   * @secure
   */
  archivePassportHandleArchivePassport = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/passport/archive/${id}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name DeleteScannedPassportHandleDeleteScannedPassport
   * @summary Delete existing file from a passport.
   * @request DELETE:/v1/crew/passport/file/{id}
   * @secure
   */
  deleteScannedPassportHandleDeleteScannedPassport = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/passport/file/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name UploadPassportFileUploadScannedPassport
   * @summary Upload and read scanned passport to an existing passport data.
   * @request PUT:/v1/crew/passport/file/{id}
   * @secure
   */
  uploadPassportFileUploadScannedPassport = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadPassportFileHandlerResponse, any>({
      path: `/v1/crew/passport/file/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name GetAllPassportGetAllPassports
   * @summary Get list of passports for the logged-in crew.
   * @request GET:/v1/crew/passport
   * @secure
   */
  getAllPassportGetAllPassports = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetListByCrewIdHandlerResponse, any>({
      path: `/v1/crew/passport`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name StorePassportHandle
   * @summary Create a new passport for the logged-in crew.
   * @request POST:/v1/crew/passport
   * @secure
   */
  storePassportHandle = (
    data: StorePassportRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfPassportDto, any>({
      path: `/v1/crew/passport`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Passport
   * @name UploadPassportUploadScannedFileAndCreatePassport
   * @summary Upload and read scanned passport.
   * @request POST:/v1/crew/passport/read
   * @secure
   */
  uploadPassportUploadScannedFileAndCreatePassport = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfPassportDto, any>({
      path: `/v1/crew/passport/read`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up
   * @name CreateMcuCreateMedicalCheckUp
   * @summary Store crew's a medical check-up record.
   * @request POST:/v1/crew/mcu
   * @secure
   */
  createMcuCreateMedicalCheckUp = (
    data: CreateMedicalCheckUpRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfMedicalCheckUpDto, any>({
      path: `/v1/crew/mcu`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up
   * @name GetMcuListGetAllMedicalCheckUps
   * @summary Get list of medical check-up of the logged in crew.
   * @request GET:/v1/crew/mcu
   * @secure
   */
  getMcuListGetAllMedicalCheckUps = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetMedicalCheckUpListResponse, any>({
      path: `/v1/crew/mcu`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up
   * @name UploadAndCreateMcuUploadScannedFileAndCreateMedicalCheckUp
   * @summary Upload scanned Medical Check Up and create new medical check up record.
   * @request POST:/v1/crew/mcu/upload
   * @secure
   */
  uploadAndCreateMcuUploadScannedFileAndCreateMedicalCheckUp = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfMedicalCheckUpDto, any>({
      path: `/v1/crew/mcu/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up File
   * @name DeleteScannedMcuDeleteScannedMedicalCheckUp
   * @summary Delete crew's scanned medical check-up file record given the specific File ID.
   * @request DELETE:/v1/crew/mcu/file/{mcuFileId}
   * @secure
   */
  deleteScannedMcuDeleteScannedMedicalCheckUp = (
    mcuFileId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/mcu/file/${mcuFileId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up File
   * @name DeleteScannedMcuByMcuIdDeleteScannedMedicalCheckUp
   * @summary Delete crew's scanned medical check-up file records that tied to the given MCU ID.
   * @request DELETE:/v1/crew/mcu/file/all/{mcuId}
   * @secure
   */
  deleteScannedMcuByMcuIdDeleteScannedMedicalCheckUp = (
    mcuId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/mcu/file/all/${mcuId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Medical Check-Up File
   * @name UploadScannedMcuUploadScannedMedicalCheckUp
   * @summary Upload crew's scanned medical check-up result.
   * @request PUT:/v1/crew/mcu/file/{mcuId}
   * @secure
   */
  uploadScannedMcuUploadScannedMedicalCheckUp = (
    mcuId: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadMedicalCheckUpFileResponse, any>({
      path: `/v1/crew/mcu/file/${mcuId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name JobVacancySearchAvailableJobVacancy
   * @summary Search a job vacancy by its department, position, vessel type, and/or location
   * @request GET:/v1/crew/job-vacancy
   * @secure
   */
  jobVacancySearchAvailableJobVacancy = (
    query?: {
      Department?: string | null;
      Position?: string | null;
      TypeOfVessel?: string | null;
      Location?: string | null;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchJobVacancyResponse, any>({
      path: `/v1/crew/job-vacancy`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name JobVacancyApplyJobVacancy
   * @summary Apply for a job vacancy
   * @request POST:/v1/crew/job-vacancy/apply
   * @secure
   */
  jobVacancyApplyJobVacancy = (
    data: ApplyJobVacancySpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/job-vacancy/apply`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name JobVacancyWithdrawApplication
   * @summary Withdraw from an applied job vacancy
   * @request POST:/v1/crew/job-vacancy/withdraw
   * @secure
   */
  jobVacancyWithdrawApplication = (
    data: WithdrawJobApplicationSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/job-vacancy/withdraw`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name JobVacancyGetAppliedJobVacancies
   * @summary Get job vacancies that the crew already applied
   * @request GET:/v1/crew/job-vacancy/applied
   * @secure
   */
  jobVacancyGetAppliedJobVacancies = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetJobVacancyApplicationsResponse, any>({
      path: `/v1/crew/job-vacancy/applied`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name UploadIdCardUploadScannedFileAndCreateIdCard
   * @summary Upload and read and store scanned id card photo.
   * @request POST:/v1/crew/id-card/read
   * @secure
   */
  uploadIdCardUploadScannedFileAndCreateIdCard = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfIdCardDto, any>({
      path: `/v1/crew/id-card/read`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name ArchiveIdCardHandleArchiveIdCard
   * @summary Archive existing id card data.
   * @request PUT:/v1/crew/id-card/archive/{id}
   * @secure
   */
  archiveIdCardHandleArchiveIdCard = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/id-card/archive/${id}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name DeleteScannedIdCardHandleDeleteScannedIdCard
   * @summary Delete existing file from an id card.
   * @request DELETE:/v1/crew/id-card/file/{id}
   * @secure
   */
  deleteScannedIdCardHandleDeleteScannedIdCard = (
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/id-card/file/${id}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name UploadScannedIdCardHandleUploadScannedIdCard
   * @summary Upload and read scanned id card photo to an existing id card data.
   * @request PUT:/v1/crew/id-card/file/{id}
   * @secure
   */
  uploadScannedIdCardHandleUploadScannedIdCard = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUploadIdCardFileHandlerResponse, any>({
      path: `/v1/crew/id-card/file/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name GetAllIdCardsHandleGetAllIdCards
   * @summary Get list of ID Cards data for the logged-in crew.
   * @request GET:/v1/crew/id-card
   * @secure
   */
  getAllIdCardsHandleGetAllIdCards = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetIdCardsByCrewIdHandlerResponse, any>({
      path: `/v1/crew/id-card`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ID Card
   * @name StoreIdCardHandleStoreIdCard
   * @summary Store new id card data.
   * @request POST:/v1/crew/id-card
   * @secure
   */
  storeIdCardHandleStoreIdCard = (
    data: StoreIdCardRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfIdCardDto, any>({
      path: `/v1/crew/id-card`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags HomeSchool
   * @name HomeSchoolAddHomeSchool
   * @summary Add home school
   * @request POST:/v1/crew/home-school
   * @secure
   */
  homeSchoolAddHomeSchool = (
    data: AddHomeSchoolSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfHomeSchool, any>({
      path: `/v1/crew/home-school`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Expiring Documents
   * @name GetExpiringDocumentsSummaryHandle
   * @summary Get logged in crew's expiring documents summary.
   * @request GET:/v1/crew/expiring-documents/summary
   * @secure
   */
  getExpiringDocumentsSummaryHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetSummaryResponse, any>({
      path: `/v1/crew/expiring-documents/summary`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Expiring Documents
   * @name GetListGetAllExpiringTravelDocuments
   * @summary Get all the expiring travel documents (expired in 6 months) owned by the logged in crew.
   * @request GET:/v1/crew/travel-document/expiring-documents
   * @secure
   */
  getListGetAllExpiringTravelDocuments = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetExpiringTravelDocumentResponse, any>({
      path: `/v1/crew/travel-document/expiring-documents`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EmergencyContacts
   * @name EmergencyContactsAddEmergencyContact
   * @summary Add new Emergency Contact
   * @request POST:/v1/crew/emergency-contact
   * @secure
   */
  emergencyContactsAddEmergencyContact = (
    data: AddEmergencyContactSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfEmergencyContact, any>({
      path: `/v1/crew/emergency-contact`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Connections
   * @name GetListGetAllConnections
   * @summary Get crew's connection(s).
   * @request GET:/v1/crew/connection
   * @secure
   */
  getListGetAllConnections = (
    query?: { Name?: string | null; Page?: number; PageSize?: number },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetConnectionsResponse, any>({
      path: `/v1/crew/connection`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Suggestion Connections
   * @name GetSuggestionsGetAllSuggestions
   * @summary Get crew's connection(s) suggestions.
   * @request GET:/v1/crew/connection/suggestions
   * @secure
   */
  getSuggestionsGetAllSuggestions = (
    query?: { page?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfListOfGetCrewSuggestionsResponse, any>({
      path: `/v1/crew/connection/suggestions`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Connections
   * @name GetTotalConnectionsHandle
   * @summary Get logged in crew's number of connections.
   * @request GET:/v1/crew/connection/count
   * @secure
   */
  getTotalConnectionsHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetTotalConnectionsHandlerResponse, any>(
      {
        path: `/v1/crew/connection/count`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags Crew Connections
   * @name RemoveRemoveConnection
   * @summary Remove an existing connection.
   * @request DELETE:/v1/crew/connection/{connectionId}
   * @secure
   */
  removeRemoveConnection = (connectionId: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/connection/${connectionId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Connection Request
   * @name AcceptAcceptConnectionRequest
   * @summary Accept an incoming connection request.
   * @request PUT:/v1/crew/connection-request/accept/{requestId}
   * @secure
   */
  acceptAcceptConnectionRequest = (
    requestId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/connection-request/accept/${requestId}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Connection Request
   * @name CreateCreateConnectionRequest
   * @summary Create a connection request.
   * @request POST:/v1/crew/connection-request
   * @secure
   */
  createCreateConnectionRequest = (
    data: CreateConnectionRequestRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfCreateConnectionRequestHandlerResponse,
      any
    >({
      path: `/v1/crew/connection-request`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Connection Request
   * @name GetListGetAllConnectionRequests
   * @summary Get a connection request sent from other crews to the logged in crew.
   * @request GET:/v1/crew/connection-request
   * @secure
   */
  getListGetAllConnectionRequests = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetConnectionRequestsResponse, any>({
      path: `/v1/crew/connection-request`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Crew Connection Request
   * @name RejectRejectConnectionRequest
   * @summary Reject an incoming connection request.
   * @request PUT:/v1/crew/connection-request/reject/{requestId}
   * @secure
   */
  rejectRejectConnectionRequest = (
    requestId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/connection-request/reject/${requestId}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name ArchiveCertificateHandle
   * @summary Archive existing certificate owned by the logged in crew.
   * @request POST:/v1/crew/archive-certificate/{id}
   * @secure
   */
  archiveCertificateHandle = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/archive-certificate/${id}`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name CertificateRemoveCertificateFile
   * @summary Remove the scanned certificate of the given certificate id.
   * @request DELETE:/v1/crew/certificate/file/{certificateId}
   * @secure
   */
  certificateRemoveCertificateFile = (
    certificateId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/crew/certificate/file/${certificateId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name CertificateGetUrl
   * @summary Get the URL for the scanned certificate.
   * @request GET:/v1/crew/certificate/file-url/{id}
   * @secure
   */
  certificateGetUrl = (id: string, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetCertificateUrlResponse, any>({
      path: `/v1/crew/certificate/file-url/${id}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name CertificateGetExpiringCertificates
   * @request GET:/v1/crew/certificate/expiring-certificates
   * @secure
   */
  certificateGetExpiringCertificates = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetExpiringCertificateResponse, any>({
      path: `/v1/crew/certificate/expiring-certificates`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name CreateCertificateHandle
   * @summary Record a certificate for the logged in crew. All other certificates of same type and same name will automatically be archived.
   * @request POST:/v1/crew/certificate
   * @secure
   */
  createCertificateHandle = (
    data: CreateCertificateRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfStoreCertificateHandlerResponse, any>({
      path: `/v1/crew/certificate`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name GetAllCertificatesHandleGetAllCertificates
   * @summary Get list of Certificate data for the logged-in crew.
   * @request GET:/v1/crew/certificate
   * @secure
   */
  getAllCertificatesHandleGetAllCertificates = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetAllCertificatesResponse, any>({
      path: `/v1/crew/certificate`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name GetArchivedCertificateHandle
   * @summary Get archived certificates.
   * @request GET:/v1/crew/archive-certificate
   * @secure
   */
  getArchivedCertificateHandle = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetArchivedCertificatesResponse, any>({
      path: `/v1/crew/archive-certificate`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name UploadAndCreateCertificateHandle
   * @summary Upload a scanned certificate and create a new record. Only read the last page of pdf.
   * @request POST:/v1/crew/certificate/upload
   * @secure
   */
  uploadAndCreateCertificateHandle = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadAndCreateCertificateRecordHandlerResponse,
      any
    >({
      path: `/v1/crew/certificate/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name UploadAndCreateCocHandle
   * @summary Upload a scanned certificate of competency and create a new record. Only read the last page of pdf.
   * @request POST:/v1/crew/coc/upload
   * @secure
   */
  uploadAndCreateCocHandle = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadAndCreateCertificateRecordHandlerResponse,
      any
    >({
      path: `/v1/crew/coc/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name UploadAndCreateCoeHandle
   * @summary Upload a scanned certificate of endorsement and create a new record. Only read the last page of pdf.
   * @request POST:/v1/crew/coe/upload/{id}
   * @secure
   */
  uploadAndCreateCoeHandle = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadAndCreateCertificateRecordHandlerResponse,
      any
    >({
      path: `/v1/crew/coe/upload/${id}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name UploadAndCreateCopHandle
   * @summary Upload a scanned certificate of proficiency and create a new record. Only read the last page of pdf.
   * @request POST:/v1/crew/cop/upload
   * @secure
   */
  uploadAndCreateCopHandle = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadAndCreateCertificateRecordHandlerResponse,
      any
    >({
      path: `/v1/crew/cop/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name UploadAndCreateCorHandle
   * @summary Upload a scanned certificate of competency and create a new record. Only read the last page of pdf.
   * @request POST:/v1/crew/cor/upload/{id}
   * @secure
   */
  uploadAndCreateCorHandle = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfUploadAndCreateCertificateRecordHandlerResponse,
      any
    >({
      path: `/v1/crew/cor/upload/${id}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate
   * @name UploadCertificateUploadFile
   * @summary Upload a scanned certificate for the given certificate id. Only accepts pdf, jpg, & png. Max file size 10 MB.
   * @request PUT:/v1/crew/certificate/upload/{id}
   * @secure
   */
  uploadCertificateUploadFile = (
    id: string,
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCertificateDto, any>({
      path: `/v1/crew/certificate/upload/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchCop
   * @summary Search available certificate of proficiency by name.
   * @request GET:/v1/crew/certificate/cop
   * @secure
   */
  searchSearchCop = (
    query?: { Search?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCopHandlerResponse, any>({
      path: `/v1/crew/certificate/cop`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchCoc
   * @summary Search available certificate of competencies by name.
   * @request GET:/v1/crew/certificate/coc
   * @secure
   */
  searchSearchCoc = (
    query?: { Search?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCocHandlerResponse, any>({
      path: `/v1/crew/certificate/coc`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
