/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FacebookLoginHandlerRequest,
  ResponseWrapperOfFacebookLoginHandlerResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Facebook<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Login
   * @name FacebookLoginHandle
   * @summary Log in crew user using facebook account
   * @request POST:/v1/facebook/login
   * @secure
   */
  facebookLoginHandle = (
    data: FacebookLoginHandlerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfFacebookLoginHandlerResponse, any>({
      path: `/v1/facebook/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
