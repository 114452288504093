/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddDeviceTokenSpec,
  CreateCrewUserSpec,
  CreateUserSpec,
  CrewRequestOtpSpec,
  ForgotPasswordRequestSpec,
  LoginRequest,
  ResetPasswordSpec,
  ResponseWrapperOfCheckPhoneNumberEmailAvailabilityResponse,
  ResponseWrapperOfCompanyLoginHandlerResponse,
  ResponseWrapperOfCrewLoginHandlerResponse,
  ResponseWrapperOfDeviceTokenResponse,
  ResponseWrapperOfGetUserInfoResponse,
  ResponseWrapperOfListOfGetNotificationByUserIdHandlerResponse,
  ResponseWrapperOfString,
  ResponseWrapperOfUserLoginToken,
  SubmitOtpSpec,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class User<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Crew
   * @name CheckPhoneNumberEmailAvailabilityHandle
   * @summary Check availability of mobile phone number and email address.
   * @request GET:/v1/user/crew/availability
   * @secure
   */
  checkPhoneNumberEmailAvailabilityHandle = (
    query?: { MobilePhone?: string | null; Email?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfCheckPhoneNumberEmailAvailabilityResponse,
      any
    >({
      path: `/v1/user/crew/availability`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name LoginHandle
   * @summary API to login crew with email and password.
   * @request POST:/v1/user/crew/login
   * @secure
   */
  loginHandle = (data: LoginRequest, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfCrewLoginHandlerResponse, any>({
      path: `/v1/user/crew/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name LoginHandle2
   * @request POST:/v1/user/company/login
   * @originalName loginHandle
   * @duplicate
   * @secure
   */
  loginHandle2 = (data: LoginRequest, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfCompanyLoginHandlerResponse, any>({
      path: `/v1/user/company/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    }); /**
 * No description
 * 
 * @tags User
 * @name UserRegisterUserCrew
 * @summary Register user's data. For crew user type, need to include OTP, and if its invalid,
            the crew's data won't be stored. If its valid, the OTP will be removed, and can't be used anymore.
            For company user, use the other API.
 * @request POST:/v1/user/crew/register
 * @secure
 */
  userRegisterUserCrew = (
    data: CreateCrewUserSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfUserLoginToken, any>({
      path: `/v1/user/crew/register`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserGet
   * @summary Get user information of the logged in user. For company user.
   * @request GET:/v1/user
   * @secure
   */
  userGet = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetUserInfoResponse, any>({
      path: `/v1/user`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserGetCrewUser
   * @summary Get user information of the logged in user.
   * @request GET:/v1/user/crew
   * @secure
   */
  userGetCrewUser = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfGetUserInfoResponse, any>({
      path: `/v1/user/crew`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserGetUserNotification
   * @summary Get user notification
   * @request GET:/v1/user/crew/notification
   * @secure
   */
  userGetUserNotification = (params: RequestParams = {}) =>
    this.http.request<
      ResponseWrapperOfListOfGetNotificationByUserIdHandlerResponse,
      any
    >({
      path: `/v1/user/crew/notification`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserRequestOtp
   * @summary For crew to request OTP to their email, before they can continue the registration.
   * @request POST:/v1/user/crew/otp/send
   * @secure
   */
  userRequestOtp = (data: CrewRequestOtpSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/user/crew/otp/send`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UserAddDeviceToken
   * @summary Record a device token for the logged in user.
   * @request POST:/v1/user/device-token
   * @secure
   */
  userAddDeviceToken = (data: AddDeviceTokenSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfDeviceTokenResponse, any>({
      path: `/v1/user/device-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags UserRegistration
   * @name UserRegistrationHandle
   * @summary Register a new company user data.
   * @request POST:/v1/user/register
   * @secure
   */
  userRegistrationHandle = (data: CreateUserSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfUserLoginToken, any>({
      path: `/v1/user/register`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Otp
   * @name OtpSubmitOtp
   * @summary Submit OTP Code that sent to the logged-in user's email. If success, it'll mark the user as OTP-verified. Only for company user
   * @request POST:/v1/user/otp/submit
   * @secure
   */
  otpSubmitOtp = (data: SubmitOtpSpec, params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/user/otp/submit`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Otp
   * @name OtpResendOtp
   * @summary Resend verification OTP to the logged-in company user.
   * @request POST:/v1/user/otp/resend
   * @secure
   */
  otpResendOtp = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/user/otp/resend`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ForgotPassword
   * @name ForgotPasswordRequestForgotPasswordLink
   * @summary Request a forgot password link to the logged-in user's registered email.
   * @request POST:/v1/user/forgot-password
   * @secure
   */
  forgotPasswordRequestForgotPasswordLink = (
    data: ForgotPasswordRequestSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/user/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ForgotPassword
   * @name ForgotPasswordResetPassword
   * @summary Reset user's password
   * @request POST:/v1/user/forgot-password/reset-password
   * @secure
   */
  forgotPasswordResetPassword = (
    data: ResetPasswordSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/user/forgot-password/reset-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
