/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ResponseWrapperOfListOfSearchCompaniesHandlerResponse,
  ResponseWrapperOfListOfSearchVesselHandlerResponse,
  ResponseWrapperOfSearchCityCountryResponse,
  ResponseWrapperOfSearchCityResponse,
  ResponseWrapperOfSearchCocHandlerResponse,
  ResponseWrapperOfSearchCopHandlerResponse,
  ResponseWrapperOfSearchCountryResponse,
  ResponseWrapperOfSearchHandlerResponse,
  ResponseWrapperOfSearchHandlerResponse2,
  ResponseWrapperOfSearchHandlerResponse3,
  ResponseWrapperOfSearchResponse,
  ResponseWrapperOfString,
  UpdateCountriesCitiesAutofillDbRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Autofill<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchVesselType
   * @summary Search vessel type by name. Will return max 10 results.
   * @request GET:/v1/autofill/vessel-type/search
   * @secure
   */
  searchSearchVesselType = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchHandlerResponse, any>({
      path: `/v1/autofill/vessel-type/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchVessel
   * @summary Search vessel by name. Will return max 10 results.
   * @request GET:/v1/autofill/vessels/search
   * @secure
   */
  searchSearchVessel = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfListOfSearchVesselHandlerResponse, any>({
      path: `/v1/autofill/vessels/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchVaccinationType
   * @summary Search vaccination type by name. Will return max 10 results.
   * @request GET:/v1/autofill/vaccination-type/search
   * @secure
   */
  searchSearchVaccinationType = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchHandlerResponse2, any>({
      path: `/v1/autofill/vaccination-type/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchVaccinationNames
   * @summary Search vaccination name by its name and vaccination type id (optional). Will return max 10 results.
   * @request GET:/v1/autofill/vaccination-name/search
   * @secure
   */
  searchSearchVaccinationNames = (
    query?: { SearchTerm?: string | null; VaccinationTypeId?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchHandlerResponse3, any>({
      path: `/v1/autofill/vaccination-name/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchRanks
   * @summary Search ranks by name. Will return max 10 results.
   * @request GET:/v1/autofill/ranks/search
   * @secure
   */
  searchSearchRanks = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchResponse, any>({
      path: `/v1/autofill/ranks/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchCityHandle
   * @summary Search through list of cities. Only shown 10 results.
   * @request GET:/v1/autofill/city
   * @secure
   */
  searchCityHandle = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCityResponse, any>({
      path: `/v1/autofill/city`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchCityCountryHandle
   * @summary Search through list of city and country. Only shown 20 results.
   * @request GET:/v1/autofill/city-country
   * @secure
   */
  searchCityCountryHandle = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCityCountryResponse, any>({
      path: `/v1/autofill/city-country`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchCountryHandle
   * @summary Search through list of countries. Only shown 10 results.
   * @request GET:/v1/autofill/country
   * @secure
   */
  searchCountryHandle = (
    query?: {
      SearchTerm?: string | null;
      CountryWithSupportedNationality?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCountryResponse, any>({
      path: `/v1/autofill/country`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name UpdateCountriesCitiesAutofillDbHandle
   * @summary Update autofill data for Countries and Cities.
   * @request POST:/v1/autofill/countries-cities/update
   * @secure
   */
  updateCountriesCitiesAutofillDbHandle = (
    data: UpdateCountriesCitiesAutofillDbRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/autofill/countries-cities/update`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name UpdateUpdateDepHubVesselsData
   * @summary Upload a CSV file containing data of vessels.
   * @request POST:/v1/autofill/vessels/upload
   * @secure
   */
  updateUpdateDepHubVesselsData = (
    data: { request?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/autofill/vessels/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchCop2
   * @summary Search available certificate of proficiency by name.
   * @request GET:/v1/autofill/cop
   * @secure
   */
  searchSearchCop2 = (
    query?: { Search?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCopHandlerResponse, any>({
      path: `/v1/autofill/cop`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchCompanies
   * @summary Search company by name. Will return max 10 results.
   * @request GET:/v1/autofill/companies/search
   * @secure
   */
  searchSearchCompanies = (
    query?: { SearchTerm?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfListOfSearchCompaniesHandlerResponse,
      any
    >({
      path: `/v1/autofill/companies/search`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Autofill
   * @name SearchSearchCoc2
   * @summary Search available certificate of competencies by name.
   * @request GET:/v1/autofill/coc
   * @secure
   */
  searchSearchCoc2 = (
    query?: { Search?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfSearchCocHandlerResponse, any>({
      path: `/v1/autofill/coc`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
