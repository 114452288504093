import { parseCookies } from 'nookies';
import { cookies } from 'utils/constants';
import * as Sentry from '@sentry/nextjs';
import { Autofill } from 'utils/api/Autofill';
import { Company } from 'utils/api/Company';
import { Crew } from 'utils/api/Crew';
import { Facebook } from 'utils/api/Facebook';
import { Google } from 'utils/api/Google';
import { GuestInformation } from 'utils/api/GuestInformation';
import { Healthcheck } from 'utils/api/Healthcheck';
import { JobVacancy } from 'utils/api/JobVacancy';
import { Nationalities } from 'utils/api/Nationalities';
import { Notification } from 'utils/api/Notification';
import { User } from 'utils/api/User';
import { AxiosRequestConfig } from 'axios';
import { HttpClient } from './http-client';
import Config from 'utils/config';
import crypto from 'crypto';

export const Axios = new HttpClient();

export const apiConfig = () => {
  const API_KEY = Config.apiKey;
  const API_SECRET = Config.apiSecret;
  const buffered = Buffer.from(API_SECRET, 'utf-8');
  const hashedSecret = crypto
    .createHash('sha256')
    .update(buffered)
    .digest('hex');

  return {
    key: API_KEY,
    secret: hashedSecret,
  };
};

Axios.instance.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_API;

Axios.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const API_CONFIG = apiConfig();
  if (config.withCredentials) {
    const token = parseCookies(config.context || {})[cookies.token];
    config.headers.Authorization = `bearer ${API_CONFIG.key}:${API_CONFIG.secret}:${token}`;
  } else {
    config.headers.Authorization = `bearer ${API_CONFIG.key}:${API_CONFIG.secret}`;
  }

  return config;
});

Axios.instance.interceptors.response.use(
  (res) => {
    if (res?.data && !res.data.isSuccess) {
      Sentry.captureMessage('User request fail');
    }
    return res;
  },
  (err) => {
    Sentry.captureException(err);
    return Promise.reject(err);
  }
);

const API = {
  ...new Autofill(Axios),
  ...new Company(Axios),
  ...new Crew(Axios),
  ...new Facebook(Axios),
  ...new Google(Axios),
  ...new GuestInformation(Axios),
  ...new Healthcheck(Axios),
  ...new JobVacancy(Axios),
  ...new Nationalities(Axios),
  ...new Notification(Axios),
  ...new User(Axios),
};

export default API;
