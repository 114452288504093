/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  RegisterCompanyDetailsSpec,
  ResponseWrapperOfCheckWebsiteAvailabilityHandlerResponse,
  ResponseWrapperOfCompanyDetailResponse,
  ResponseWrapperOfGetCertificatesResponse,
  ResponseWrapperOfShortlistedApplicantNumberHandlerResponse,
  ResponseWrapperOfString,
  SetJobApplicationStatusRequest,
  ShortlistApplicantRequest,
  UpdateCompanyDetailsSpec,
  UpdateLocationRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Company<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Company
   * @name CheckWebsiteAvailabilityCheck
   * @summary Check whether a website is already used on OnBoard's existing registered Company or not.
   * @request GET:/v1/company/website-availability
   * @secure
   */
  checkWebsiteAvailabilityCheck = (
    query?: { Website?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfCheckWebsiteAvailabilityHandlerResponse,
      any
    >({
      path: `/v1/company/website-availability`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name CompanyRegisterCompanyDetails
   * @summary Registers company detail for the logged in company user.
   * @request POST:/v1/company
   * @secure
   */
  companyRegisterCompanyDetails = (
    data: RegisterCompanyDetailsSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCompanyDetailResponse, any>({
      path: `/v1/company`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name CompanyGetCompanyDetails
   * @summary Get company details of the logged in user.
   * @request GET:/v1/company
   * @secure
   */
  companyGetCompanyDetails = (params: RequestParams = {}) =>
    this.http.request<ResponseWrapperOfCompanyDetailResponse, any>({
      path: `/v1/company`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name CompanyUpdateCompanyDetails
   * @summary Updates company detail for the logged in company user.
   * @request PUT:/v1/company
   * @secure
   */
  companyUpdateCompanyDetails = (
    data: UpdateCompanyDetailsSpec,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfCompanyDetailResponse, any>({
      path: `/v1/company`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Company
   * @name CompanyUploadCompanyLogo
   * @summary Uploads company logo. Only accepts *.png, *.jpg, & *.jpeg, must be under 2 MB.
   * @request POST:/v1/company/logo
   * @secure
   */
  companyUploadCompanyLogo = (
    data: { file?: File | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/company/logo`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Update Company Location
   * @name UpdateLocationHandle
   * @summary Update company's location information.
   * @request PUT:/v1/company/location
   * @secure
   */
  updateLocationHandle = (
    data: UpdateLocationRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/company/location`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Certificate of Proficiencies
   * @name GetCopFindCertificateofProficiencies2
   * @summary Get list of available certificate of proficiencies for company to create job vacancy.
   * @request GET:/v1/company/certificate/cop
   * @secure
   */
  getCopFindCertificateofProficiencies2 = (
    query?: { Search?: string | null },
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfGetCertificatesResponse, any>({
      path: `/v1/company/certificate/cop`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags JobVacancy
   * @name GetShortlistedApplicantNumberHandle
   * @summary Get number of shortlisted applicant
   * @request GET:/v1/company/application/shortlist/count
   * @secure
   */
  getShortlistedApplicantNumberHandle = (
    query?: { jobVacancyId?: string },
    params: RequestParams = {}
  ) =>
    this.http.request<
      ResponseWrapperOfShortlistedApplicantNumberHandlerResponse,
      any
    >({
      path: `/v1/company/application/shortlist/count`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Applicant
   * @name SetJobApplicationStatusHandle
   * @summary Update applicants application status.
   * @request PUT:/v1/company/applicant/status/{id}
   * @secure
   */
  setJobApplicationStatusHandle = (
    id: string,
    data: SetJobApplicationStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/company/applicant/status/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Job Applicant
   * @name ShortlistApplicantHandle
   * @summary Shortlist an applicant that applied to job vacancy created by the company.
   * @request PUT:/v1/company/applicant/shortlist
   * @secure
   */
  shortlistApplicantHandle = (
    data: ShortlistApplicantRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ResponseWrapperOfString, any>({
      path: `/v1/company/applicant/shortlist`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
